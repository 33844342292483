import request from '@/api/request'

// 官网用户兴趣收集
export function userInterestCollectionAdd(data) {
    return request({
      url: '/sports-officer-console/system/userInterestCollection/add', 
      method: 'post',
      data:data
    })
  }
// 官网隐私政策
export function resourcesSearch(query) {
  return request({
    url: '/resources/search', 
    method: 'get',
    params: query
  })
}