import axios from 'axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
// console.log(Option, '090898756435465476867980')
console.log(process.env)
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL:  process.env.VUE_APP_BASEURL,
  baseURL: process.env.VUE_APP_BASEURL,
  // 超时
  timeout: 10000
})

export default service